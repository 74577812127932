export const logos = [
    {
        name: "New Year",
        startDate: "2024-12-31 00:00:00",
        endDate: "2025-01-01 00:00:00",
        lottieFile: "/lottie/NewYear2025/blue.lottie",
        color: 'blue'
    },
    {
        name: "New Year",
        startDate: "2024-12-31 00:00:00",
        endDate: "2025-01-01 00:00:00",
        lottieFile: "/lottie/NewYear2025/white.lottie",
        color: 'white'
    },
    {
        name: "Republic Day",
        startDate: "2025-01-25 18:00:00",
        endDate: "2025-01-27 00:00:00",
        lottieFile: "/lottie/Republic2025/logo.lottie",
        color: 'blue'
    },
    {
        name: "Republic Day",
        startDate: "2025-01-25 18:00:00",
        endDate: "2025-01-27 00:00:00",
        lottieFile: "/lottie/Republic2025/logo.lottie",
        color: 'white'
    },
    {
        name: "Valentine's Day",
        startDate: "2025-02-13 18:00:00",
        endDate: "2025-02-15 00:00:00",
        lottieFile: "/lottie/Valentines2025/blue.lottie",
        color: 'blue'
    },
    {
        name: "Valentine's Day",
        startDate: "2025-02-13 18:00:00",
        endDate: "2025-02-15 00:00:00",
        lottieFile: "/lottie/Valentines2025/white.lottie",
        color: 'white'
    },
    {
        name: "Holi",
        startDate: "2025-03-13 18:00:00",
        endDate: "2025-03-15 00:00:00",
        lottieFile: "/lottie/Holi2025/logo.lottie",
        color: 'blue'
    },
    {
        name: "Holi",
        startDate: "2025-03-13 18:00:00",
        endDate: "2025-03-15 00:00:00",
        lottieFile: "/lottie/Holi2025/logo.lottie",
        color: 'white'
    },
];